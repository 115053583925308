import React, { useCallback, useContext } from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { css } from "@emotion/core";
import { useTheme } from "emotion-theming";
import Link from "../../Atoms/Link";

import {
  NotoSmall,
  NotoLarge,
  OswaldOverline2,
  OswaldOverline3,
} from "../../Atoms/Typography";
import { rem, scaleSpacing, mediaquery } from "../../../../styles/theme";
import Svg from "../../Atoms/Svg";
import Button from "../../Atoms/Button";
import { useTranslation } from "react-i18next";
import { UIContext } from "../../../contexts/ui";
import useWindowSize from "../../../hooks/useWindowSize";

const Categories = styled.div`
  width: 100%;
  flex-direction: column;
  justify-content: center;
  border-left: ${rem(6)} solid ${({ activeColor }) => activeColor};
  display: none;
  transform: scale(${({ shouldResize }) => (shouldResize ? 0.72 : 1)});

  .main-navigation && {
    display: flex;
  }
  ${mediaquery.md(css`
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    background-color: transparent;
    border-left: none;
    max-width: calc(var(--col) * 16 - var(--gutter-size));
    width: auto;

    .main-navigation && {
      display: none;
    }
  `)};
`;

const menuStyleMobile = css`
  ${NotoLarge};
  padding: ${scaleSpacing(6)} 0;
  margin: 0 ${scaleSpacing(6)};
  text-decoration: none;
`;

const menuStyleDesktop = css`
  ${OswaldOverline3};
  padding: 0 ${scaleSpacing(2)};
  margin: 0 auto;
  position: relative;
  color: inherit;
  white-space: nowrap;
  &.active:after {
    content: "";
    position: absolute;
    bottom: -${scaleSpacing(7)};
    left: 0;
    width: 100%;
    border-radius: ${scaleSpacing(2)};
    height: ${rem(5)};
  }

  ${mediaquery.l(css`
    ${OswaldOverline2};

    &.active:after {
      bottom: -${scaleSpacing(6)};
    }
  `)};
`;

const Category = styled(Link)`
  ${menuStyleMobile}
  border-bottom: 1px solid ${({ separator }) => separator};

  &::after {
    ${({ shouldResize }) =>
      shouldResize && `bottom: ${scaleSpacing(-10.25)} !important;`}
  }

  ${mediaquery.md(css`
    ${menuStyleDesktop}
    margin: 0 ${scaleSpacing(2)};
    border-bottom: none;
  `)};

  ${mediaquery.l(css`
    margin: 0 ${scaleSpacing(4)};
  `)};

  ${mediaquery.xl(css`
    margin: 0 ${scaleSpacing(6)};
  `)};

  &.active:after {
    background-color: ${({ color }) => color};
  }
`;

const CategoryButtons = styled.div`
  display: none;
  transform: scale(${({ shouldResize }) => (shouldResize ? 0.8 : 1)});
  ${mediaquery.md(css`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 ${scaleSpacing(4)};
    padding: 0 ${scaleSpacing(10)} 0 0;
  `)};
`;

const CategoryButtonContainer = styled.div`
  display: none;

  ${mediaquery.md(css`
    display: block;
    position: relative;
    margin-left: auto;
    text-align: center;
    padding: 0 ${scaleSpacing(4)};
    bottom: ${scaleSpacing(2)};
  `)};
`;

const CategoryButtonText = styled.span`
  display: none;
  ${({ color }) =>
    css`
      ${mediaquery.md(css`
        display: block;
        position: absolute;
        bottom: -${scaleSpacing(4)};
        left: 50%;
        transform: translateX(-50%);
        color: ${color && color};
        ${NotoSmall}
        letter-spacing: 0;
        text-transform: none;
      `)};
    `};
`;

const CategoryButton = styled(Button)`
  display: none;

  ${mediaquery.md(css`
    display: block;
    ${menuStyleDesktop}
    padding: 0 ${scaleSpacing(3)};

    span {
      margin: 0;
    }
  `)};
`;

const CategoryButtonMobile = styled(Button)`
  ${menuStyleMobile}
  text-align: left;
  &[type="button"] {
    border-bottom: 1px solid ${({ menuSeparatorColor }) => menuSeparatorColor};
  }
  .main-navigation && {
    color: ${({ menuColor }) => menuColor};
  }

  span {
    margin-right: ${scaleSpacing(4)};
  }

  &:after {
    display: none;
  }

  ${mediaquery.md(css`
    display: none;
  `)};
`;

const CategoryNavigationLinks = ({
  menu,
  onClickUser,
  color,
  isInsideNav,
  showNewsletterIcon,
  showProfileIcon,
  showSearchIcon,
  onClickSearch,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { openModal } = useContext(UIContext);
  const { isMobile } = useWindowSize();

  const shouldResize = menu.length > 3 && !isMobile;

  const handleManageNewsletters = useCallback(() => {
    openModal({
      modalId: "newsletter",
      modalParams: { template: "allNewsletterModal" },
    });
  }, [openModal]);

  return (
    <>
      <Categories activeColor={theme?.primary} shouldResize={shouldResize}>
        {menu?.map(
          (category, index) =>
            category && (
              <Category
                shouldResize={shouldResize}
                linkTheme="dark"
                link={category.link_path}
                key={index}
                color={theme?.primary}
                separator={theme?.menuSeparatorColor}
                css={
                  isInsideNav &&
                  css`
                    color: ${theme?.menuColor};
                  `
                }
              >
                {category.link_label}
              </Category>
            )
        )}
        {showNewsletterIcon && (
          <CategoryButtonMobile
            key="newsletter"
            onClick={handleManageNewsletters}
            menuColor={theme?.menuColor}
            menuSeparatorColor={theme?.menuSeparatorColor}
          >
            <Svg
              name="newsletter"
              size={25}
              height={24}
              fill={isInsideNav ? theme?.menuColor : color}
            />
            {t("newsletter_menu_title")}
          </CategoryButtonMobile>
        )}
        {showProfileIcon && (
          <CategoryButtonMobile
            key="myaccount"
            onClick={onClickUser}
            menuColor={theme?.menuColor}
            menuSeparatorColor={theme?.menuSeparatorColor}
          >
            <Svg
              name="account"
              size={24}
              height={24}
              fill={isInsideNav ? theme?.menuColor : color}
            />
            {t("user_menu_title")}
          </CategoryButtonMobile>
        )}
        {showSearchIcon && (
          <CategoryButtonMobile
            key="search"
            onClick={onClickSearch}
            menuColor={theme?.menuColor}
            menuSeparatorColor={theme?.menuSeparatorColor}
          >
            <Svg
              name="search"
              size={24}
              height={24}
              fill={isInsideNav ? theme?.menuColor : color}
            />
            {t("search_placeholder")}
          </CategoryButtonMobile>
        )}
      </Categories>

      <CategoryButtons shouldResize={shouldResize}>
        {showNewsletterIcon && !isInsideNav && (
          <CategoryButtonContainer>
            <CategoryButton key="newsletter" onClick={handleManageNewsletters}>
              <Svg
                name="newsletter"
                size={25}
                height={24}
                fill={theme?.primary}
              />
              <CategoryButtonText color={theme.primary}>
                {t("navigation_newsletter_label")}
              </CategoryButtonText>
            </CategoryButton>
          </CategoryButtonContainer>
        )}
        {showSearchIcon && !isInsideNav && (
          <CategoryButton key="search" onClick={onClickSearch}>
            <Svg
              name="search"
              size={24}
              height={24}
              fill={isInsideNav ? theme?.menuColor : color}
            />
          </CategoryButton>
        )}
        {showProfileIcon && !isInsideNav && (
          <CategoryButton key="myaccount" onClick={onClickUser}>
            <Svg
              name="account"
              size={24}
              height={24}
              fill={isInsideNav ? "white" : color}
            />
          </CategoryButton>
        )}
      </CategoryButtons>
    </>
  );
};

CategoryNavigationLinks.propTypes = {
  menu: PropTypes.array.isRequired,
  isInsideNav: PropTypes.bool,
  color: PropTypes.string,
  onClickUser: PropTypes.func,
  showNewsletterIcon: PropTypes.bool,
  showProfileIcon: PropTypes.bool,
  showSearchIcon: PropTypes.bool,
  onClickSearch: PropTypes.func,
};

CategoryNavigationLinks.defaultProptypes = {
  color: "white",
  isInsideNav: false,
  onClickUser: () => {},
  onClickSearch: () => {},
};

export default React.memo(CategoryNavigationLinks);
